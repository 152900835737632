import gql from 'graphql-tag'

export const FairStores = gql`
  query fairStores($id: ID!) {
    fairStores(id: $id) {
      id
      state
      store {
        id
        name
        slug
        description
        profile {
          id
          picture {
            id
            url
          }
        }
      }
    }
  }
`
