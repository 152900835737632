<template>
  <v-card
    max-height="calc(100vh - 48px - 32px)"
    class="rounded-lg"
    >
    <v-card-text
      class="black--text"
      >
      <div
        class="text-center"
        >
        <v-icon
          x-large
          color="primary"
          >
          mdi-storefront-outline
        </v-icon>
      </div>

      <div
        class="text-h6 font-weight-bold text-center mb-3 primary--text"
        >
        Tiendas
      </div>

      <div
        class="body-1 mb-1 text-center black--text"
        >
        <v-snackbar
          v-model="copied"
          color="success darken-1"
          >
          <v-icon>mdi-content-copy</v-icon>

          Enlace copiado con éxito.
        </v-snackbar>

        Si quieres invitar tiendas a que se unan al evento, <span @click="copyInvite" style="cursor: pointer" class="info--text font-weight-medium">haz click aquí</span> para copiar el enlace de invitación, o <span @click="downloadQr" style="cursor: pointer" class="info--text font-weight-medium">aquí para descargar un código QR <v-icon class="mt-n1" color="info">mdi-qrcode-scan</v-icon></span>
      </div>

      <div
        class="mb-3"
        >
        <div
          class="d-none"
          id="canvas"
          ></div>
        <canvas
          id="canvas_"
          class="d-none"
          ></canvas>
      </div>
      
      <div
        class="d-flex flex-wrap justify-center"
        style="gap: 8px"
        >
        <template
          v-for="store in stores"
          >
          <store
            :store="store"
            @reload="reload"
            ></store>
        </template>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { Filesystem, Directory } from '@capacitor/filesystem'
import { Share } from '@capacitor/share'

import html2canvas from 'html2canvas'
import { jsPDF } from "jspdf"
import QRCodeStyling from 'qr-code-styling'

import { mapGetters } from 'vuex'
import { FairStores } from '@/graphql/queries/fairs/fair_stores'

import Store from './fair_stores/Store'

export default {
  data: () => ({
    stores: [],
    copied: false,
  }),

  props: {
    fair: {
      type: Object,
      required: true
    }
  },

  created () {
    this.fetchStores()
  },

  computed: {
    ...mapGetters(['baseUrl', 'isNative'])
  },

  methods: {
    fetchStores () {
      this.$apollo.query({
        query: FairStores,
        variables: {
          id: this.fair.id
        },
        fetchPolicy: 'no-cache'
      }).then ( res => {
        this.stores = res.data.fairStores
      })
    },

    reload () {
      this.fetchStores()
    },

    copyInvite () {
      navigator.clipboard.writeText(`${this.baseUrl}?join=${this.fair.id}`)

      this.copied = true
    },

    async downloadQr () {
      const qrCode = new QRCodeStyling({
        width: 1200,
        height: 1200,
        type: "png",
        data: `${this.baseUrl}?join=${this.fair.id}`,
        image: require('@/assets/full_logo.png'),
        dotsOptions: {
          type: "rounded"
        },
        backgroundOptions: {
          color: "#e9ebee",
        },
        imageOptions: {
          crossOrigin: "anonymous",
          margin: 20
        }
      });

      if (this.isNative) {
        try {
          var div = document.getElementById('canvas')
          qrCode.append(div);

          setTimeout( async () => {
            var svg = div.getElementsByTagName('svg')[0]
            var xml = new XMLSerializer().serializeToString(svg)
            var svg64 = btoa(xml)
            var b64start = 'data:image/svg+xml;base64,'
            var image64 = b64start + svg64

            var canvas = document.getElementById('canvas_');
            // get canvas context for drawing on canvas
            var context = canvas.getContext('2d');
            canvas.width = 1200;
            canvas.height = 1200;

            var image = new Image();

            image.onload = function () { // async (happens later)
              // clear canvas
              context.clearRect(0, 0, 1200, 1200);
              // draw image with SVG data to canvas
              context.drawImage(image, 0, 0, 1200, 1200);
              // snapshot canvas as png
              var pngData = canvas.toDataURL('image/png');
              // pass png data URL to callback
              //callback(pngData);
              pngData
            }; // end async
            // start loading SVG data into in memory image

            image.src = image64;

            setTimeout( async () => {
              var base64Data = canvas.toDataURL()

              const savedFile = await Filesystem.writeFile({
                path: 'Mi QR.png',
                data: base64Data,
                directory: Directory.Cache
              }).then( res => {
                return Filesystem.getUri({
                  directory: Directory.Cache,
                  path: 'Mi QR.png'
                });
              }).then( uriResult => {
                return Share.share({
                  title: 'Mi QR.png',
                  text: '',
                  url: uriResult.uri,
                });
              })

              this.$emit('success')
            }, 500)
          }, 500)
        } catch (error) {
          alert(error)
        }
      } else {
        qrCode.download({ name: this.fair.name, extension: "png" });
      }
    },
  },

  components: {
    Store,
  }
}
</script>
