<template>
  <v-card
    outlined
    class="rounded-lg"
    width="100%"
    >
    <v-card-text
      class="d-flex flex-row align-center"
      style="gap: 12px; position: relative"
      >
      <v-avatar
        size="80"
        v-if="store.store.profile.picture"
        >
        <v-img
          :src="url + store.store.profile.picture.url"
          ></v-img>
      </v-avatar>

      <v-avatar
        v-else
        color="secondary"
        size="80"
        >
        <v-icon
          color="white"
          size="50"
          >
          mdi-account
        </v-icon>
      </v-avatar>

      <div
        style="flex: 1"
        >
        <div
          class="primary--text body-1 font-weight-medium store-card-box"
          >
          {{ store.store.name }}
        </div>

        <div
          class="primary--text store-card-box"
          >
          {{ store.store.description }}
        </div>

        <div
          class="d-flex justify-space-between align-end fill-width"
          >
          <v-select
            :key="store.state"
            v-model="state"
            :items="states"
            label="Estado"
            dense
            flat
            hide-details
            class="mt-5 rounded-lg"
            style="max-width: 150px"
            >
            <template #selection="{ item }">
              <v-chip small :color="item.color">
                {{ item.text }}
              </v-chip>
            </template>
          </v-select>

          <div
            class="blue--text font-weight-medium"
            style="cursor: pointer"
            @click="redirect"
            >
            ver tienda
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { UpdateFairStoreState } from '@/graphql/mutations/fairs/fair_stores'

export default {
  data: () => ({
    state: null,
    states: [
      {
        value: 'accepted',
        text: 'Aceptada',
        color: 'success'
      },
      {
        value: 'rejected',
        text: 'Rechazada',
        color: 'error'
      },
    ]
  }),

  props: {
    store: {
      type: Object,
      required: true
    }
  },

  created () {
    this.state = this.store.state

    if (this.state == 'pending') {
      this.states.push(
        {
          value: 'pending',
          text: 'Pendiente',
          color: 'orange'
        }
      )
    }
  },

  watch: {
    state (val) {
      if (val != 'pending' && val != this.store.state) {
        this.updateState()
      }
    }
  },

  methods: {
    updateState () {
      var state = this.state == 'accepted' ? 'accept' : 'reject'

      this.$apollo.mutate({
        mutation: UpdateFairStoreState,
        variables: {
          input: {
            state: state,
            id: this.store.id
          }
        }
      }).then ( res => {
        this.$emit('reload')
      })
    },

    redirect () {
      let routeData = this.$router.resolve({ name: 'profile', params: { slug: this.store.store.slug }})
      window.open(routeData.href, '_blank')
    }
  },

  computed: {
    ...mapGetters(['url', 'baseUrl'])
  },
}
</script>
